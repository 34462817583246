import React from 'react';

function ResultsTable({course_units}) {
    var results_rows = 
    	course_units.sort().map((subject)=>
    			<tr key={subject.id}>
					<td>{subject.subject_code}</td>
					<td>{subject.subject_name}</td>
					<td>{subject.dateTime}</td>
					<td>{'<more>'}</td>
				</tr>
			)
    return (
			<table className="table table-boardered table-sm table-striped">
				<thead>
					<tr>
						<td>paper code</td>
					    <td>paper name</td>
						<td>date & time</td>
						<td>{'Actions'}</td>
					</tr>
				</thead>
				<tbody>
					{ results_rows }
                </tbody>
			</table>
    );
}

export default ResultsTable;